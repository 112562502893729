import React from "react";
import { styled } from "@linaria/react";
import colors from "../../constants/colors/colors";
import font from "../../constants/font/font";
const StyledParagraph = styled.p`
  color: ${colors.black};
  // font-size: ${font.size.medium};
  margin-block-end: 1em;
  margin-block-start: 1em;
  &:first-child {
    margin-block-start: 0;
  }
  &:last-child {
    margin-block-end: 0;
  }
`;
const Paragraph = (props) => /* @__PURE__ */ React.createElement(StyledParagraph, {
  ...props
});
export default Paragraph;
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsiL1VzZXJzL3Rhc3RpL3RyaWFuZ2xlL3VpL3NyYy9jb21wb25lbnRzL1BhcmFncmFwaC9QYXJhZ3JhcGgudHN4Il0sCiAgInNvdXJjZXNDb250ZW50IjogWyJpbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCI7XG5pbXBvcnQgeyBzdHlsZWQgfSBmcm9tIFwiQGxpbmFyaWEvcmVhY3RcIjtcblxuaW1wb3J0IGNvbG9ycyBmcm9tIFwiLi4vLi4vY29uc3RhbnRzL2NvbG9ycy9jb2xvcnNcIjtcbmltcG9ydCBmb250IGZyb20gXCIuLi8uLi9jb25zdGFudHMvZm9udC9mb250XCI7XG5cbmNvbnN0IFN0eWxlZFBhcmFncmFwaCA9IHN0eWxlZC5wYFxuICBjb2xvcjogJHtjb2xvcnMuYmxhY2t9O1xuICAvLyBmb250LXNpemU6ICR7Zm9udC5zaXplLm1lZGl1bX07XG4gIG1hcmdpbi1ibG9jay1lbmQ6IDFlbTtcbiAgbWFyZ2luLWJsb2NrLXN0YXJ0OiAxZW07XG4gICY6Zmlyc3QtY2hpbGQge1xuICAgIG1hcmdpbi1ibG9jay1zdGFydDogMDtcbiAgfVxuICAmOmxhc3QtY2hpbGQge1xuICAgIG1hcmdpbi1ibG9jay1lbmQ6IDA7XG4gIH1cbmA7XG5cbnR5cGUgUHJvcHMgPSB7XG4gIGNoaWxkcmVuPzogUmVhY3QuUmVhY3ROb2RlO1xuICBzdHlsZT86IFJlYWN0LkNTU1Byb3BlcnRpZXM7XG59O1xuXG5jb25zdCBQYXJhZ3JhcGg6IFJlYWN0LkZDPFByb3BzPiA9IChwcm9wcykgPT4gPFN0eWxlZFBhcmFncmFwaCB7Li4ucHJvcHN9IC8+O1xuXG5leHBvcnQgZGVmYXVsdCBQYXJhZ3JhcGg7XG4iXSwKICAibWFwcGluZ3MiOiAiQUFBQTtBQUNBO0FBRUE7QUFDQTtBQUVBLE1BQU0sa0JBQWtCLE9BQU87QUFBQSxXQUNwQixPQUFPO0FBQUEsa0JBQ0EsS0FBSyxLQUFLO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBO0FBZ0I1QixNQUFNLFlBQTZCLENBQUMsVUFBVSxvQ0FBQyxpQkFBRDtBQUFBLEtBQXFCO0FBQUE7QUFFbkUsZUFBZTsiLAogICJuYW1lcyI6IFtdCn0K*/