import React from "react";
import { styled } from "@linaria/react";
import colors from "../../constants/colors/colors";
import spacing from "../../constants/spacing/spacing";
const StyledAnchor = styled.a`
  border-radius: ${spacing.medium};
  color: ${colors.blue};
  outline: none;
  text-decoration: none;
  transition: box-shadow 0.25s ease, color 0.25s ease;

  &:focus-visible {
    box-shadow: 0 0 0 4px ${colors.blue}80;
  }

  &:hover {
    color: ${colors.black};
  }
`;
const Anchor = React.forwardRef((props, ref) => /* @__PURE__ */ React.createElement(StyledAnchor, {
  ref,
  ...props
}));
export default Anchor;
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsiL1VzZXJzL3Rhc3RpL3RyaWFuZ2xlL3VpL3NyYy9jb21wb25lbnRzL0FuY2hvci9BbmNob3IudHN4Il0sCiAgInNvdXJjZXNDb250ZW50IjogWyJpbXBvcnQgUmVhY3QgZnJvbSBcInJlYWN0XCI7XG5pbXBvcnQgeyBzdHlsZWQgfSBmcm9tIFwiQGxpbmFyaWEvcmVhY3RcIjtcblxuaW1wb3J0IGNvbG9ycyBmcm9tIFwiLi4vLi4vY29uc3RhbnRzL2NvbG9ycy9jb2xvcnNcIjtcbmltcG9ydCBzcGFjaW5nIGZyb20gXCIuLi8uLi9jb25zdGFudHMvc3BhY2luZy9zcGFjaW5nXCI7XG5cbmNvbnN0IFN0eWxlZEFuY2hvciA9IHN0eWxlZC5hYFxuICBib3JkZXItcmFkaXVzOiAke3NwYWNpbmcubWVkaXVtfTtcbiAgY29sb3I6ICR7Y29sb3JzLmJsdWV9O1xuICBvdXRsaW5lOiBub25lO1xuICB0ZXh0LWRlY29yYXRpb246IG5vbmU7XG4gIHRyYW5zaXRpb246IGJveC1zaGFkb3cgMC4yNXMgZWFzZSwgY29sb3IgMC4yNXMgZWFzZTtcblxuICAmOmZvY3VzLXZpc2libGUge1xuICAgIGJveC1zaGFkb3c6IDAgMCAwIDRweCAke2NvbG9ycy5ibHVlfTgwO1xuICB9XG5cbiAgJjpob3ZlciB7XG4gICAgY29sb3I6ICR7Y29sb3JzLmJsYWNrfTtcbiAgfVxuYDtcblxudHlwZSBQcm9wcyA9IHtcbiAgY2hpbGRyZW46IFJlYWN0LlJlYWN0Tm9kZTtcbiAgaHJlZjogc3RyaW5nO1xuICBzdHlsZT86IFJlYWN0LkNTU1Byb3BlcnRpZXM7XG4gIHRhcmdldD86IHN0cmluZztcbn07XG5cbmNvbnN0IEFuY2hvciA9IFJlYWN0LmZvcndhcmRSZWY8SFRNTEFuY2hvckVsZW1lbnQsIFByb3BzPigocHJvcHMsIHJlZikgPT4gKFxuICA8U3R5bGVkQW5jaG9yIHJlZj17cmVmfSB7Li4ucHJvcHN9IC8+XG4pKTtcblxuZXhwb3J0IGRlZmF1bHQgQW5jaG9yO1xuIl0sCiAgIm1hcHBpbmdzIjogIkFBQUE7QUFDQTtBQUVBO0FBQ0E7QUFFQSxNQUFNLGVBQWUsT0FBTztBQUFBLG1CQUNULFFBQVE7QUFBQSxXQUNoQixPQUFPO0FBQUE7QUFBQTtBQUFBO0FBQUE7QUFBQTtBQUFBLDRCQU1VLE9BQU87QUFBQTtBQUFBO0FBQUE7QUFBQSxhQUl0QixPQUFPO0FBQUE7QUFBQTtBQVdwQixNQUFNLFNBQVMsTUFBTSxXQUFxQyxDQUFDLE9BQU8sUUFDaEUsb0NBQUMsY0FBRDtBQUFBLEVBQWM7QUFBQSxLQUFjO0FBQUE7QUFHOUIsZUFBZTsiLAogICJuYW1lcyI6IFtdCn0K*/